<template>
  <div v-if="isLoading" class="marketing-container">
    <VueText style="margin: 30px 0 5px;" sizeLevel="6" weightLevel="2" class="text">
      {{ marketingVideosHeader }}</VueText
    >

    <div class="marketing-list">
      <div
        v-for="(item, index) in marketingVideoUrlList"
        :id="`marketing-item-${index}`"
        :key="index"
        class="marketing-item"
      >
        <iframe :src="`${item.embedVideo}`" />
        <div style="margin: 15px ">
          <VueHeadline style="margin-bottom: 5px;" level="5">{{ item.name }}</VueHeadline>
          <VueText style="text-align: start;" sizeLevel="3" color="grey-30">{{
            item.description
          }}</VueText>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { Common } from '@/services/Api/index';
import StaticContent from '@/services/Api/staticContents';
import vimeoUtils from '@/mixins/vimeoUtils.js';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';

export default {
  name: 'VideoSeries',
  components: { VueText, VueHeadline },
  mixins: [vimeoUtils],
  data() {
    return {
      marketingVideoUrlList: [],
      marketingVideosHeader: '',
      isLoading: false,
    };
  },
  mounted() {
    this.getMarketingPageTitle();
    this.getMarketingVideoSeries();
    this.isLoading = true;
  },
  methods: {
    getMarketingPageTitle() {
      StaticContent.getStaticContent('MarketingPageTitle').then(res => {
        if (!res.data.Data) return;
        this.marketingVideosHeader = res?.data?.Data?.content;
      });
    },
    getMarketingVideoSeries() {
      Common.getMarketingVideoSeries().then(res => {
        const {
          Data: { marketingVideoUrlList },
        } = res.data;
        this.marketingVideoUrlList = this.getVideoThumbs(marketingVideoUrlList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.marketing {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &-item {
    margin-top: 2%;
    padding: 4% 0 1%;
    background-color: rgb(249, 249, 249);
  }
}
iframe {
  width: 100% !important;
  height: 200px !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  iframe {
    width: 100% !important;
    height: 445px !important;
  }
}
</style>
